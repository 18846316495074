import "./Loader.scss";
// import loader from "../../assets/images/bg/loader.png"
import loader from "../../assets/images/bg/loader.gif"




const Loader = () => {

  return (
    <div className="loading-screen">
        <p className="loading-text">From <br/>Ting Ting Ti Ding to<br/>cha-ching!</p>
        <img src={loader} alt="" className="loader-img"/>
    </div>
    );
};

export default Loader;
