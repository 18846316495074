import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const TermsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="howtoredeem-us-popup" title={"Terms & conditions"}>
      <strong>
        <p dir="ltr">
          TERMS &amp; CONDITIONS FOR GOOD DAY BANK OF SMALL WINS CAMPAIGN
        </p>
      </strong>
      <ol>
        <strong>
          <li dir="ltr">
            <p dir="ltr">SCOPE OF THE CAMPAIGN</p>
          </li>
        </strong>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            The Good Day Bank of Small Wins is a consumer campaign sponsored and
            organized by Britannia Industries Ltd.
          </p>
        </li>
      </ol>
      <p dir="ltr">
        The cashback is applicable for everyone who receives the dummy currency
        notes of Good Day upon purchase of certain new apparel from Myntra
        online store, receipt of laundry from uClean laundry service, and
        purchase of pre-loved clothes from Bombay Closet Cleanse, a thrift store
        in Mumbai.
      </p>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            These terms and conditions apply to the Campaign and aspects
            thereof. By participating in the Campaign, the Customer fully and
            unconditionally agree to and accept these Terms and Conditions which
            are available on BOSW.in/ www.bankofsmallwins.in
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            These Terms and Conditions are subject to our Privacy Policy which
            can be accessed at in/
            <a href="http://www.bankofsmallwins.in">www.bankofsmallwins.in</a>
          </p>
        </li>
      </ol>
      <ol start={2}>
        <strong>
          <li dir="ltr">
            <p dir="ltr">DEFINITIONS:</p>
          </li>
        </strong>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            “<strong>Agency</strong>” shall mean either Talented Webco Private
            Limited or Premier Sales Promotions Pvt. Ltd
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            “<strong>Campaign</strong>” shall mean this campaign
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            “<strong>Campaign Period</strong>” shall mean the period of the
            <strong>Campaign</strong> defined hereunder
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            <strong>“Customer”(s)</strong> shall mean any person who buys jeans
            from Myntra online store, gives their clothes for laundry with
            uClean, an online laundry service, or purchases pre-owned clothes
            from Bombay Closet Cleanse, Mumbai. <br />
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            “<strong>Terms &amp; Conditions</strong>” shall mean these Terms and
            Conditions for the Campaign
          </p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">
            “<strong>Britannia</strong>” or “<strong>Promoter</strong>” shall
            mean Britannia Industries Limited who is conducting and promoting
            this Campaign
          </p>
        </li>
      </ol>
      <ol start={7}>
        <li dir="ltr">
          <p dir="ltr">
            “<strong>Product</strong>” shall mean jeans purchased from Myntra
            online store OR clothes collected from uClean (an online laundry
            service) OR pre-owned clothes purchased from Bombay Closet Cleanse,
            Mumbai
          </p>
        </li>
      </ol>
      <p dir="ltr">
        “<strong>Winner</strong>”(s) shall mean the Customer finding the dummy
        currency notes of Good Day from the Product
      </p>
      <p dir="ltr">
        Explanation: The terms and conditions herein applicable for Customer s,
        shall be deemed to be applicable to the Winner.
      </p>
      <ol start={3}>
        <strong>
          <li dir="ltr">
            <p dir="ltr">PROMOTION PERIOD</p>
          </li>
        </strong>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            The Campaign will be valid from 22-03-2024to 10-09-2024 (“
            <strong>Campaign Period</strong>”).
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            Britannia reserves the right to cancel/amend/extend the Campaign
            Period without giving any notice or intimation.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            No requests or questions shall be entertained regarding extending or
            amending the Campaign Period in any manner.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            Entries coming outside the Campaign period will not be eligible for
            the Campaign.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <strong>
          <li dir="ltr">
            <p dir="ltr">TERRITORY &amp; APPLICABLE LAWS</p>
          </li>
        </strong>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Subject to other criteria and conditions stated in the terms of the
            Campaign, the Campaign will be open to all residents of India,
            (except the state of Tamil Nadu).
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            The Campaign is subject to all applicable central, state and local
            laws and regulations.
          </p>
        </li>
      </ol>
      <ol start={5}>
        <strong>
          <li dir="ltr">
            <p dir="ltr">ELIGIBILITY TO PARTICIPATE</p>
          </li>
        </strong>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            This Campaign will be open to every individual aged above 18,
            residing in India.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            Customer should not be facing any criminal/civil or any other legal
            proceedings which may prejudice the participation in the Campaign in
            any way.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            The Customer’s communication address shall be within the area of
            delivery serviceable by popular courier services.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            Employees of Britannia and their relatives, and promotion agencies
            and their respective relatives their respective agents,
            distributors, retailers and any other channel partner, and their
            immediate family members are not eligible to participate in the
            Campaign.
          </p>
        </li>
      </ol>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">
            Customer agrees to share their phone WhatsApp number to Britannia/
            Agency, as applicable (if not previously shared) for written/ verbal
            communications from Britannia/ Agency, as applicable with regard to
            the Campaign.
          </p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">
            Britannia/ Agency reserves the right to exclude any person from
            participating or winning the Campaign on grounds of misconduct,
            misrepresentation, coercion, fraud, deceit or for any other reasons,
            as it may deem fit and proper. No communication in this regard from
            the Customer or his representatives shall be entertained by
            Britannia/ Agency.
          </p>
        </li>
      </ol>
      <ol start={6}>
        <strong>
          <li dir="ltr">
            <p dir="ltr">HOW TO PARTICIPATE</p>
          </li>
        </strong>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            In order to participate in this Campaign, Customers have to
            receive/find the dummy currency notes of Good Day and redeem their
            cashback on www.BOSW.in
          </p>
        </li>
      </ol>
      <ol start={7}>
        <strong>
          <li dir="ltr">
            <p dir="ltr">SELECTION OF WINNERS</p>
          </li>
        </strong>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Customer who finds the dummy currency note of Good Day with a valid
            unique code in the Product shall be the Winner and is eligible to
            get a guaranteed cashback of the amount mentioned in the dummy
            currency note of Good Day.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            The decision of Britannia/ Agency shall be final and binding on the
            Customer in this matter. Britannia/ Agency shall in no case
            entertain any questions, correspondence, enquiries on the manner of
            the selection of Winners.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            Britannia/ Agency shall, at all times, have the right to exercise
            its discretion and amend the Terms and Conditions applicable to
            Winners, in order to ensure equity and fairness for all Customers.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            The onus of providing a valid, working UPI ID to receive the
            cashback is on the Customer. Even if by mistake, the consumer enters
            an incorrect UPI ID, the unique code will be treated as used.
          </p>
        </li>
      </ol>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">
            In case of the customer winning an amount of Rs. 50,000, they will
            be contacted by the BigCity team via call/email separately to gather
            documents required by the law. Failure to contact the winner through
            call/email after a fixed number of attempts will lead to
            disqualification, and result in the winner forgoing the prize money.
            Upon the successful submission &amp; verification of these documents
            only, will the prize money, after deduction of applicable taxes, be
            transferred to the winner.
          </p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">
            Any prize money of greater than, or equal to Rs. 10,000 is subject
            to a TDS of 31.2% under Section 194B of the Income Tax Act, which
            will be deducted from the prize amount. No communication regarding
            the deduction of this amount shall be entertained.
          </p>
        </li>
      </ol>
      <ol start={8}>
        <strong>
          <li dir="ltr">
            <p dir="ltr">PRIZE</p>
          </li>
        </strong>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Customers who find the dummy currency note of Good Day shall be
            eligible to get cashback of denomination mentioned on the note.
            There will be a total of 110,005 (One Lakh Ten Thousand and Five
            only) winners in total as mentioned below :-
          </p>
        </li>
      </ol>
      <div dir="ltr" style={{ textAlign: "left" }}>
        <table>
          <colgroup>
            <col width="56" />
            <col width="205" />
            <col width="117" />
          </colgroup>
          <tbody>
            <tr>
              <td>
                <strong>
                  <p dir="ltr">Sno.</p>
                </strong>
              </td>
              <td>
                <strong>
                  <p dir="ltr">Prize</p>
                </strong>
              </td>
              <td>
                <strong>
                  {" "}
                  <p dir="ltr">Number of winners</p>
                </strong>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">1</p>
              </td>
              <td>
                <p dir="ltr">Rs. 10 Cashback Voucher:</p>
              </td>
              <td>
                <p dir="ltr">98400</p>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">2</p>
              </td>
              <td>
                <p dir="ltr">Rs. 20 Cashback Voucher:</p>
              </td>
              <td>
                <p dir="ltr">8500</p>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">3</p>
              </td>
              <td>
                <p dir="ltr">Rs. 50 Cashback Voucher:</p>
              </td>
              <td>
                <p dir="ltr">2250</p>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">4</p>
              </td>
              <td>
                <p dir="ltr">Rs. 100 Cashback Voucher:</p>
              </td>
              <td>
                <p dir="ltr">800</p>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">5</p>
              </td>
              <td>
                <p dir="ltr">Rs. 500 Cashback Voucher:</p>
              </td>
              <td>
                <p dir="ltr">35</p>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">6</p>
              </td>
              <td>
                <p dir="ltr">Rs. 2000 Cashback Voucher:</p>
              </td>
              <td>
                <p dir="ltr">10</p>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">7</p>
              </td>
              <td>
                <p dir="ltr">Rs. 50000 Cashback Voucher:</p>
              </td>
              <td>
                <p dir="ltr">5</p>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">8</p>
              </td>
              <td>
                <p dir="ltr">03 month of supply of Good Day</p>
              </td>
              <td>
                <p dir="ltr">5</p>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <strong>
                  <p dir="ltr">Total</p>
                </strong>
              </td>
              <td>
                <strong>
                  <p dir="ltr">110005</p>
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            Every user (per mobile number, per email id and per account) shall
            be eligible for only two cashback redemptions.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            Any costs associated with entering the promotion, including
            accessing the promotional website, are the responsibility of each
            Customer. Also, regular call and message rates will apply as per the
            consumer telecom operator in their respective circles.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            The Product will be provided on an "as-is where-is basis" and
            without any express or implied warranty or guarantee concerning the
            quality, suitability or comfort. Britannia/ Agency, their
            associates, directors, officers, agents, representatives shall not
            be responsible or liable for any defect/deficiency or any other
            dispute in relation to and/or concerning the Product including but
            not limited to any service or after delivery service problems/
            issues and liabilities and Britannia/ Agency will not entertain any
            claims in this regard.
          </p>
        </li>
      </ol>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">
            Britannia/ Agency are not responsible for any deficiency and/ or
            defect in, or relating to, any product/service and/or the Product or
            for any kind of consequential damages/ loss, bodily harm/ injury or
            death, in any manner whatsoever. If any Customer has any grievance
            with respect to the Product, the Customer may directly contact the
            warranty or guarantee provider, as may be applicable.
          </p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">
            The image of the Products depicted on the ads/posters/pack shots
            etc. are indicative only and the actual Product/ look of the Product
            may vary from such pictures.
          </p>
        </li>
      </ol>
      <ol start={7}>
        <li dir="ltr">
          <p dir="ltr">
            The dummy currency notes of Good Day are not actual tradable
            currency notes and is not transferable, can’t be traded, exchanged,
            sold, replaced or adjusted with anything including other prizes/
            gifts/ benefits, or cash or other benefits in lieu thereof.
          </p>
        </li>
      </ol>
      <ol start={9}>
        <li dir="ltr">
          <strong>
            <p dir="ltr">RIGHT TO USE DATA COLLECTED AS PART OF THE CAMPAIGN</p>
          </strong>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            The Customer provides express consent and hereby undertakes to
            irrevocably and unconditionally permit Britannia/ Agency to cover/
            promote the Campaign through various media including newspapers,
            radio, television, news channels, internet, point of sale materials,
            digital media etc., and shall not raise any objection or make,
            protest or demur to such coverage/ promotion.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            Each Customer / Winner understands and agrees that their entries
            including their name/ image may be used by Britannia/ Agency for
            reasonable purposes. All the rights including but not limited to the
            intellectual property rights, copyrights and all other allied,
            ancillary and subsidiary rights in the entry/ies shall vest with
            Britannia/ Agency absolutely for perpetuity, throughout the world.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            Britannia/ Agency will collect Customer’s personal information such
            as official name, including any alias, of the Customer in order to
            conduct the Campaign. If the said information is not provided,
            Customer understands that the participation of such Customer would
            be as per the sole discretion of Britannia/ Agency.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            By entering the Campaign, unless otherwise advised, each Customer
            also agree that Britannia/ Agency or its affiliates may use such
            personal information, or disclose it to other organizations for use
            in any media for future promotional, marketing and publicity
            purposes. Customer understands that Britannia/ Agency is not liable
            to acknowledge/ give credits, provide further reference, or payment
            or such other compensation or recognition to the Customer.
            Customer’s personal information may be disclosed to statutory
            authorities, if required.
          </p>
        </li>
      </ol>
      <ol start={10}>
        <strong>
          <li dir="ltr">
            <p dir="ltr">
              EXTENT OF LIABILITY/ OBLIGATION OF BRITANNIA AND AGENCY
            </p>
          </li>
        </strong>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Britannia/ Agency shall not be responsible if the Customers are not
            receiving the Prize on account of the Customer not meeting any
            Eligibility Criteria.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            Britannia/ Agency shall not be responsible for (including but not
            limited to):
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Any lost, late, or misdirected computer transmission or network,
            electronic failures, or any kind of any failure to receive entries
            owing to transmission failures or due to any technical or any other
            reason;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            If the Customer has registered himself to the DND of the telecom
            provider/ the Customer has registered with National Do Not Call
            Registry - Any failure by reason thereof.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Other conditions beyond the control of Britannia/ Agency. including
            Force Majeure
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            Even if the Customers are registered under NDNC, DND (Do Not
            Disturb), or under any other similar regulation, Britannia/ Agency
            or its Agency will still have all the authority to call the
            Customer. Customers agree to receive communications related to the
            Campaign and expressly waives of any right/ privilege, by virtue of
            them having voluntarily participated in the Campaign.
          </p>
        </li>
      </ol>
      <ol start={11}>
        <strong>
          <li dir="ltr">
            <p dir="ltr">INTELLECTUAL PROPERTY RIGHTS</p>
          </li>
        </strong>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            All right, title and interest, including but not limited to the
            Intellectual Property Rights, in the promotional material(s) and in
            any and all responses received shall vest solely and exclusively
            with Britannia at all times. Britannia or any person or entity
            permitted by Britannia shall be entitled to use the responses
            received or any information in connection with the Customer’s entry
            in any media for future promotional, marketing, publicity and any
            other purpose, without any permission and or consideration to the
            Customer.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            All material submitted in connection with the Campaign (whether
            written, audio, electronic or visual form, or a combination of
            those) or any photographs, video and/or film footage and/or audio
            recording taken of Customers are assigned to Britannia upon
            submission and become the property of Britannia exclusively.
            Britannia may use the material in any medium in any reasonable
            manner it sees fit. Copyright in any shall be deemed to have been
            assigned to Britannia and such material shall remain the sole
            property of Britannia.
          </p>
        </li>
      </ol>
      <ol start={12}>
        <strong>
          <li dir="ltr">
            <p dir="ltr">CUSTOMER CARE/ HELPLINE</p>
          </li>
        </strong>
      </ol>
      <p dir="ltr">
        For any queries pertaining to this Campaign, Customers may visit
        bosw.in/bankofsmallwins.com (“Campaign Website”) or email us at
        feedback@bigcity.in
      </p>
      <ol start={13}>
        <strong>
          <li dir="ltr">
            <p dir="ltr">GENERAL TERMS AND CONDITIONS</p>
          </li>
        </strong>
      </ol>
      <strong>
        <p dir="ltr" style={{ textAlign: "right" }}>
          Competency
        </p>
      </strong>
      <ol type="A">
        <li dir="ltr">
          <p dir="ltr">
            Each Customer represents and warrants that he/she is legally
            competent to enter into binding contracts under applicable laws. By
            taking part and/or entering into the Campaign the Customer warrants
            that all information provided by Customer regarding Customer's name,
            age, state, city, address, phone number, etc., is true, correct,
            accurate and complete.
          </p>
        </li>
      </ol>
      <strong>
        <p dir="ltr" style={{ textAlign: "right" }}>
          Queries &amp; Disputes
        </p>
      </strong>
      <br />
      <ol start={2} type="A">
        <li dir="ltr">
          <p dir="ltr">
            No queries, claims, dispute, complaints or grievances shall be
            entertained by Britannia/ Agency after 30 days from the date of the
            closure of the campaign.
          </p>
        </li>
      </ol>
      <strong>
        {" "}
        <p dir="ltr" style={{ textAlign: "right" }}>
          Disqualification of entries
        </p>
      </strong>
      <br />
      <ol start={3} type="A">
        <li dir="ltr">
          <p dir="ltr">
            Any entry that is abusive or sexually explicit or disrespectful to
            any community/religion/place/person etc. will be disqualified and
            such act will be reported to the relevant authorities and
            appropriate action shall be taken against such Customer. No
            communication in this regard will be entertained.
          </p>
        </li>
      </ol>
      <br />
      <ol start={4} type="A">
        <li dir="ltr">
          <p dir="ltr">
            Failure to comply with any of these terms and conditions (including
            instructions) will disqualify the Customer from continuing to take
            part in the Campaign and such Customer's participation will be
            invalid. Britannia/ Agency retains the sole discretion to refuse
            Customers the right to participate if it is of the opinion that
            these terms and conditions are not followed.
          </p>
        </li>
      </ol>
      <br />
      <ol start={5} type="A">
        <li dir="ltr">
          <p dir="ltr">
            The Customers undertake that he/ she shall not under any
            circumstances, engage in conduct which is fraudulent, misleading,
            deceptive, derogatory or defamatory or generally damaging the
            goodwill or reputation of the Campaign/ Britannia/ Agency. The
            Britannia/ Agency reserves the right to disqualify any Customer or
            take such other action as it may deem fit, against such Customers
            who tamper or attempts to create an undue influence with the entry
            process or submits an entry that is not in accordance with these
            terms and conditions or who has, in the opinion of Britannia/
            Agency, engaged in conduct which is fraudulent, misleading,
            deceptive, derogatory or defamatory or generally damaging the
            goodwill or reputation of the Campaign/ Britannia/ Agency/
            Celebrities associated in connection therewith.
          </p>
        </li>
      </ol>
      <br />
      <strong>
        {" "}
        <p dir="ltr" style={{ textAlign: "right" }}>
          Amendment Rights
        </p>
      </strong>
      <br />
      <ol start={6} type="A">
        <li dir="ltr">
          <p dir="ltr">
            Britannia/ Agency reserves the right to change, defer, alter, extend
            or cancel/terminate this Campaign in part or full, or change any or
            all of the terms and conditions that are applicable, without giving
            prior intimation/notice of any kind and will not be liable for any
            consequential losses/damages. Britannia/ Agency may also amend the
            type of Campaign, submission mechanism, eligibility criteria, term
            of the Campaign, and the prizes for the Campaign. Britannia/ Agency
            reserves the right to amend, temporarily suspend or withdraw the
            Campaign in its absolute discretion provided that Britannia/ Agency
            shall not exercise this right unreasonably. All decisions of
            Britannia/ Agency in respect of the Campaign and the Prizes therein
            will be final, binding and conclusive.
          </p>
        </li>
      </ol>
      <strong>
        {" "}
        <p dir="ltr" style={{ textAlign: "right" }}>
          Liability of Britannia/ Agency
        </p>
      </strong>
      <ol start={7} type="A">
        <li dir="ltr">
          <p dir="ltr">
            Britannia/ Agency shall not be liable to perform any of its/their
            respective obligations under the Campaign or in respect of the Prize
            where it is unable to do so as a result of circumstances beyond
            its/their control in the nature of fire, explosions, natural
            calamities, state emergency, riots, epidemic, pandemic, quarantine
            or any other Force Majeure condition, etc., and shall not be liable
            to compensate or pay damages to the Customer in these circumstances.
          </p>
        </li>
      </ol>
      <br />
      <ol start={8} type="A">
        <li dir="ltr">
          <p dir="ltr">
            Britannia/ Agency shall not be accountable/liable for any
            disruptions/stoppages/interruptions or cancellation of the Campaign
            due to any law and order situation or government restrictions/
            judicial pronouncements.
          </p>
        </li>
      </ol>
      <br />
      <ol start={9} type="A">
        <li dir="ltr">
          <p dir="ltr">
            Britannia/ Agency shall not be liable to award/compensate/exchange
            or in any manner pay or Campaign in case they fail to provide the
            Prize under this Campaign due to any cause beyond their reasonable
            control or foresight, including but not limited to: act of god,
            insurrection or civil disorder, religious strife, war or military
            operations, terrorist act, partial or total strikes, either internal
            or external, lock-out, epidemic, blockage of means of transport or
            of supplies, national or local emergency, earthquake, fire, storm,
            flood, water damage, governmental, regulatory or legal restrictions,
            pandemic/epidemic, area being unserviceable by Premium Courier or
            Postal Services, Or act or omissions of persons for whom Britannia/
            Agency is not responsible.
          </p>
        </li>
      </ol>
      <br />
      <ol start={10} type="A">
        <li dir="ltr">
          <p dir="ltr">
            Britannia/ Agency accept no liability, whether jointly or severally,
            for any errors or omissions, in relation to winning the Campaign.
            The Customer shall be solely responsible for any consequences which
            may arise from his/her participation in the Campaign. Customer also
            undertakes to indemnify Britannia/ Agency and its officers,
            directors, employees, representatives and agents, on the happening
            of such illegal acts committed by the Customer.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Britannia/ Agency shall not be responsible for late, falsified,
            delayed, incomplete or destroyed entries and all such entries are
            void and may be disqualified at any time. Britannia/ Agency does not
            assume any responsibility for incorrect or inaccurate capture of
            entry information, technical malfunctions, human or technical error,
            lost delayed or garbled data or transmissions, omissions,
            interruptions, deletion, defect or failures of any telephone or
            computer lines or networks, computer equipment, software or any
            combination thereof. Entry materials/ data that have been tampered
            with or altered are void.
          </p>
        </li>
      </ol>
      <br />
      <ol start={12} type="A">
        <li dir="ltr">
          <p dir="ltr">
            Britannia/ Agency is not liable for any problems, errors, or
            negligence that may arise or occur in connection with this Campaign,
            including but not limited to, any damage to the Customer's computer
            or other technical device, or software, as a result of the
            Customer's participation in this Campaign. Britannia/ Agency shall
            not be liable in any way for any failure or breach by any party in
            connection with this Campaign, prize or prizes and accepts no
            responsibility for any loss suffered by a Customer arising from this
            Campaign, prize or prizes.
          </p>
        </li>
      </ol>
      <br />
      <ol start={13} type="A">
        <li dir="ltr">
          <p dir="ltr">
            Failure to exercise or delay in exercising a right or remedy
            provided hereunder or by law does not constitute a waiver of the
            right or remedy or waiver of other rights or remedies on the part of
            Britannia/ Agency.
          </p>
        </li>
      </ol>
      <br />
      <strong>
        <p dir="ltr" style={{ textAlign: "right" }}>
          Compliance with local laws
        </p>
      </strong>
      <ol start={14} type="A">
        <li dir="ltr">
          <p dir="ltr">
            If a person chooses to access the Campaign Website from outside
            India, he/she shall do so on his/her own initiative and shall be
            responsible for compliance with applicable local laws of the country
            over and above laws applicable in India. Britannia/ Agency shall not
            be responsible for Customer’s compliance with any local laws
            (including tax laws) outside India.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            By participating in this Campaign, each Customer has specifically
            and voluntarily waived off any right or privilege, with respect to
            the management, processing, retention of information provided in
            connection with this Campaign.
          </p>
        </li>
      </ol>
      <strong>
        <p dir="ltr" style={{ textAlign: "right" }}>
          Acceptance of TnC
        </p>
      </strong>
      <ol start={16} type="A">
        <li dir="ltr">
          <p dir="ltr">
            The participation in the Campaign is purely voluntary and the same
            shall be construed as an acceptance of the terms and conditions
            stipulated herein.
          </p>
        </li>
      </ol>
      <strong>
        <p dir="ltr" style={{ textAlign: "right" }}>
          Confidentiality &amp; Publicity
        </p>
      </strong>
      <ol start={17} type="A">
        <li dir="ltr">
          <p dir="ltr">
            The entry, comments of Customers including Winners or Mega Prize
            Winners, if any or any other materials sent to Britannia/ Agency
            including feedback and other communications of any kind shall be
            deemed to be non-confidential. Britannia/ Agency is free to
            reproduce, distribute and publicly display such feedback and entries
            received towards this Campaign without limitations or obligation of
            any kind. Britannia/ Agency is also free to use any ideas, concepts,
            know-how or techniques contained in such feedback for any purpose.
            Britannia/ Agency reserves the right to analyse and summarize data
            that is collected and also reserves the right to publish,
            distribute, share summaries of the data collected with sponsors or
            other business partners without limitations or obligations of any
            kind subject to applicable laws.
          </p>
        </li>
      </ol>
      <strong>
        <p dir="ltr" style={{ textAlign: "right" }}>
          Indemnity
        </p>
      </strong>
      <ol start={18} type="A">
        <li dir="ltr">
          <p dir="ltr">
            By entering this Campaign, Customers agree to hold harmless
            Britannia/ Agency, and its officers, directors, employees,
            representatives and agents, against any and all liability, damages
            or causes of action (however named or described) with respect to or
            arising out of: (i) Customer's participation in the Campaign; (ii)
            the selection criteria or process; (iii) the administration of the
            Campaign; (iv) delivery of the prize for reasons not attributable to
            Agency and (v) the quality, warranty, functioning or anything
            relating to the prize. Customers hereby release, waive and discharge
            any and all claims of damage, loss or causes of action (including
            negligence) including but not limited to loss or damage to person or
            property which the Customer and his/her representatives or assignees
            may have, suffer or which may hereinafter accrue to the Customeror
            as a result of the Customer's participation in the Campaign or use
            of the subscription.
          </p>
        </li>
      </ol>
      <br />
      <ol start={19} type="A">
        <li dir="ltr">
          <p dir="ltr">
            The Customers acknowledge that the quality of the Prize, and the
            services of the courier service are not controlled or supervised by
            Britannia/ Agency or its Agency, hence any issue with relation to
            delay in delivery or damage in transit shall have to addressed to
            the Courier delivery partner and defect in Prize or Prize related
            services shall have to addressed to the manufacturer or service
            provider of the Prize /service alone and Britannia/ Agency or its
            Agency shall not be held responsible for it.
          </p>
        </li>
      </ol>
      <strong>
        <p dir="ltr" style={{ textAlign: "right" }}>
          Severability
        </p>
      </strong>
      <ol start={20} type="A">
        <li dir="ltr">
          <p dir="ltr">
            If any provision of these terms and conditions is challenged/ found
            to be invalid by any court having competent jurisdiction, the
            invalidity of such provision shall not affect the validity of the
            remaining provisions, which shall remain in full force and effect.
            Indian laws shall be exclusively applicable to any and all disputes
            arising out of or in connection with the Campaign or these terms and
            conditions.
          </p>
        </li>
      </ol>
      <strong>
        <p dir="ltr" style={{ textAlign: "right" }}>
          Discretion
        </p>
      </strong>
      <ol start={21} type="A">
        <li dir="ltr">
          <p dir="ltr">
            The interpretation and implementation of the Terms and Conditions
            shall be at the sole discretion of Britannia/ Agency. The decisions
            of Britannia / Agency with respect to aspects of the Campaign shall
            be final and binding, and not subject to challenge or appeal.
          </p>
        </li>
      </ol>
      <strong>
        {" "}
        <p dir="ltr" style={{ textAlign: "right" }}>
          Applicable law and dispute resolution
        </p>
      </strong>
      <ol start={22} type="A">
        <li dir="ltr">
          <p dir="ltr">
            These Terms shall be governed by and construed in accordance with
            the applicable laws in India. Any dispute including any dispute
            arising out of all matters with respect to this Campaign shall be
            subject to the exclusive jurisdiction of the Courts in Bangalore,
            India. All the disputes/complaints/queries, if any, shall be
            entertained only within one month of the Campaign getting over and
            be directed to Agency @ feedback@bigcity.in Any Winner approaching
            Britannia/ Agency with any disputes/complaints/queries after the
            expiry of the aforesaid one month from 13-05-2024 shall not be
            considered for resolution, subject to the sole discretion of
            Britannia/ Agency.
          </p>
        </li>
      </ol>
    </Popup>
  );
};

export default TermsPopup;
