import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import "./ThankYou.scss";
import { useTranslation } from "react-i18next";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
import MainLayout from "../../layouts/MainLayout";
import card1 from "../../assets/images/bg/card 3.png";
import card2 from "../../assets/images/bg/card 1.png";
import card3 from "../../assets/images/bg/card 2.png";

import arrowleft from "../../assets/images/bg/leftarrow.png";
import arrowright from "../../assets/images/bg/rightarrow.png";
import API from "../../api";

const ThankYou = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [slideNo, setSlideNo] = useState(1);
  const images = [card1, card2, card3];
  const intervalTime = 2000; // Change image every 5 seconds

  useEffect(() => {
    // Track the Thank You page visit when the component mounts
    API.sendSMS()
      .then(() => {
        gtagTrackEvent(GA_EVENTS.Thank_You);
      })
      .catch((err) => console.log(err));

    // Set up automatic image scrolling
    const intervalId = setInterval(() => {
      setSlideNo((prevSlideNo) => (prevSlideNo % images.length) + 1);
    }, intervalTime);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [images.length]); // Run effect whenever images length changes

  useEffect(() => {
    // Track the Congratulations page visit when the popup closes
    if (!isPopupOpen) {
      gtagTrackEvent(GA_EVENTS.Congratulations);
    }
  }, [isPopupOpen]);

  const amount = localStorage.getItem("amount");

  // Define corresponding texts for each image
  const imageTexts = [
    "And a few special notes will also give you three months of free Good Days",
    "Every note comes with a 'bonus small win' that money can't buy",
    "Some notes come with an autograph by your favorite celebrities.",
  ];

  // Function to handle image change
  const handleImageChange = (index: any) => {
    setSlideNo(index + 1); // Index starts from 0, slideNo starts from 1
  };

  return (
    <MainLayout className="thank-you-page" center={false}>
      {isPopupOpen && (
        <div className="congratulation-wrapper">
          <div className="congratulation-container">
            <div className="close" onClick={() => setIsPopupOpen(false)}>
              x
            </div>
            <>
              <p className="text1">{t("tyPage.newUser.text1")}</p>
              {amount && parseInt(amount) === 50000 ? (
                <p className="text2">
                  Congratulations
                  <br />
                  You will receive a verification call within 24-48 business
                  hours to claim your reward
                </p>
              ) : (
                <p className="text2">
                  Congratulations
                  <br />
                  Your bank account will be happier in
                  <br /> 24-48 business hours
                </p>
              )}
            </>
          </div>
        </div>
      )}
      <div className="background"></div>
      <div className="container">
        <div className="container-title">
          3 reasons to <br />
          save your note:
        </div>
        <div className="slider">
          <div
            className="left-arrow"
            onClick={() =>
              handleImageChange((slideNo - 2 + images.length) % images.length)
            }
          >
            {/*<img src={arrowleft} alt="left" />*/}
          </div>
          <div style={{ width: "75%" }}>
            <img className="image-box" src={images[slideNo - 1]} alt="note" />
          </div>
          <div
            className="right-arrow"
            onClick={() => handleImageChange(slideNo % images.length)}
          >
            {/*<img src={arrowright} alt="right" />*/}
          </div>
        </div>
        <div className="container-note">{imageTexts[slideNo - 1]}</div>
      </div>
    </MainLayout>
  );
};

export default ThankYou;
