import { getCookie, setCookie } from "../../lib/utils";
import { SET_USER_ID, CLEAR_ACCESS_DETAILS, SET_ACCESS_TOKEN } from "../types";

const initialState = {
  userKey: "",
  dataKey: "",
  accessToken: "",
};

type ActionType =
  | {
      type: "SET_USER_ID";
      payload: {
        userKey: string;
        dataKey: string;
      };
    }
  | {
      type: "SET_ACCESS_TOKEN";
      payload: string;
    }
  | {
      type: "CLEAR_ACCESS_DETAILS";
    };
export default function authReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case SET_USER_ID:
      let masterKey = getCookie("good-day-small-wins-id");
      if (!masterKey) {
        setCookie("good-day-small-wins-id", action.payload.userKey);
      }
      // console.log("userKey", action.payload.userKey);
      return {
        ...state,
        userKey: action.payload.userKey,
        dataKey: action.payload.dataKey,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case CLEAR_ACCESS_DETAILS:
      return {
        ...state,
        userKey: "",
        dataKey: "",
        accessToken: "",
      };
    default:
      return state;
  }
}
