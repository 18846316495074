import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ROUTES } from "./lib/consts";
import Home from "./pages/Home/Home";
import Register from "./pages/Register/Register";
import ThankYou from "./pages/ThankYou/ThankYou";
import { useEffect } from "react";
import API from "./api";
import { useGlobalLoaderContext } from "./helpers/GlobalLoader";
import store from "./store/store";
import { setUserIdentification } from "./store/actions/authAction";
import { addCaptchaScript, logoutUser } from "./lib/utils";
import { useAuthentication } from "./hooks/useAuthentication";
import PrivateRoute from "./helpers/PrivateRoute";
import SelectPayout from "./pages/SelectPayout/CashbackPayout";
import UpiPayout from "./pages/UpiPayout/CashbackPayout";
import BankPayout from "./pages/BankPayout/CashbackPayout";
import Code from "./pages/Code/CashbackPayout";
import img from "./assets/images/bg/Desktop.png";
import HowToRedeemPage from "./pages/HowToRedeemPage";

function App() {
  const { showLoader, hideLoader } = useGlobalLoaderContext();
  const { isLoggedIn } = useAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    API.initialize(showLoader, hideLoader);
    // if (isLoggedIn) {
    API.createUser()
      .then((response) => {
        store.dispatch(setUserIdentification(response));
        if (!response.isLoggedIn && isLoggedIn) {
          logoutUser();
          toast.info("Your session has been expired");
        }
        addCaptchaScript();
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          logoutUser();
          navigate(ROUTES.HOME);
        } else {
          console.error("Error:", error);
        }
      });
    // }
  }, []);
  return (
    <div className="App">
      <div className="mobile-only">
        <img src={img} />
      </div>
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.HOW_TO_REDEEM_PAGE} element={<HowToRedeemPage />} />
        <Route path={ROUTES.REGISTER} element={<Register />} />

        <Route
          path={ROUTES.CODE}
          element={
            <PrivateRoute>
              <Code onSubmit={(values) => console.log(values)} />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.SELECT_PAYOUT}
          element={
            <PrivateRoute>
              <SelectPayout onSubmit={(values) => console.log(values)} />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.UPI_PAYOUT}
          element={
            <PrivateRoute>
              <UpiPayout onSubmit={(values) => console.log(values)} />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.BANK_PAYOUT}
          element={
            <PrivateRoute>
              <BankPayout onSubmit={(values) => console.log(values)} />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.THANK_YOU}
          element={
            <PrivateRoute>
              <ThankYou />
            </PrivateRoute>
          }
        />
      </Routes>
      <ToastContainer position="bottom-center" hideProgressBar={true} />
    </div>
  );
}

export default App;
