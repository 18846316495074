/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react";
import HowToRedeemPopup from "../components/Popups/HowToRedeemPopup";
import Popup from "../helpers/Popup";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../lib/consts";
import poster from "../assets/images/poster.jpg";
import back from "../assets/images/back.png";

const HowToRedeemPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the path contains '/how-to-redeem'
    if (location.pathname === "/about-campaign") {
      // Navigate to the HowToRedeemPage
      navigate("/about-campaign");
    }
  }, [navigate]);

  const goToHomePage = () => {
    // Navigate to the home page
    navigate("/");
  };
  return (
    <div>
      {/* Home button */}
      <button
        className="home-button"
        onClick={goToHomePage}
        style={{
          backgroundImage: `url(${back})`,
          position: "absolute",
          backgroundPosition: "center",
          right: "1em",
          width: "50px",
          height: "50px",
          backgroundColor: "transparent",
          border: "none",
          backgroundRepeat: "no-repeat",
        }}
      />
      <div className="poster">
        <img src={poster} />
      </div>
    </div>
  );
};

export default HowToRedeemPage;
