import { PropsWithChildren, useState } from "react";
import Logo from "../assets/images/Logoolder.png";
import Topframe from "../assets/images/bg/topframe.png";
import ThankYouTopFrame from "../assets/images/bg/tytopframe.png";
import PaymentTopFrame from "../assets/images/bg/payouttopframe.png";
import Video from "../assets/images/bg/Scratch.gif";

import Bottomframe from "../assets/images/bg/bottomframe.png";
import ThankYouBottomframe from "../assets/images/bg/tyfooter.png";

import Footer from "../assets/images/bg/footer-img.png";
import MenuIcon from "../assets/images/menu-icon.svg";
import "./MainLayout.scss";
import SideMenu from "../components/SideMenu/SideMenu";
import { MODAL_TYPES, useGlobalModalContext } from "../helpers/GlobalModal";
import { useTranslation } from "react-i18next";
import { LANGUAGE, LANGUAGES, ROUTES } from "../lib/consts";
import { useLocation, useNavigate } from "react-router-dom";

const MainLayout = ({
  children,
  className,
  center = true,
}: { className: string; center?: boolean } & PropsWithChildren) => {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const { showModal } = useGlobalModalContext();
  const location = useLocation();
  const navigate = useNavigate();
  // const { t } = useTranslation();
  const { t, i18n } = useTranslation();
  // console.log(location.pathname);
  return (
    <div className={"main-layout " + className}>
      <SideMenu open={showSideMenu} onClose={() => setShowSideMenu(false)} />
      <header>
        {/*<select
          className="language-selection header-lang"
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
          }}
        >
          {Object.keys(LANGUAGES).map((key) => {
            if (i18n.language === key) {
              return (
                <option key={key} value={key} selected>
                  {LANGUAGES[key as LANGUAGE]}
                </option>
              );
            }

            return (
              <option key={key} value={key}>
                {LANGUAGES[key as LANGUAGE]}
              </option>
            );
          })}
        </select>*/}
        <img
          src={Logo}
          alt="logo"
          className="logo"
          onClick={() => navigate(ROUTES.HOME, { replace: true })}
        />
        <img
          src={MenuIcon}
          onClick={() => setShowSideMenu(true)}
          alt="menu"
          className="menu-icon"
        />
      </header>
      {/* {
            location.pathname === ROUTES.THANK_YOU &&
            <img
            src={ThankYouTopFrame}
            alt="logo-top"
            className="ty-logo-frame-top"
          />
          } */}

      {location.pathname !== ROUTES.THANK_YOU &&
        location.pathname !== ROUTES.CODE && (
          <img src={Topframe} alt="logo-top" className="logo-frame-top" />
        )}
      {location.pathname === ROUTES.CODE && (
        <img src={Video} alt="logo-top" className="logo-frame-top" />
      )}
      {location.pathname === ROUTES.SELECT_PAYOUT && (
        <img src={PaymentTopFrame} alt="logo-top" className="logo-frame-top" />
      )}
      {location.pathname === ROUTES.UPI_PAYOUT && (
        <img
          src={PaymentTopFrame}
          alt="logo-top"
          className="logo-frame-top-upi"
        />
      )}

      {location.pathname !== ROUTES.THANK_YOU && (
        <img src={Bottomframe} alt="logo-top" className="logo-frame-bottom" />
      )}

      {center ? (
        <div className="content">{children}</div>
      ) : (
        <div>{children}</div>
      )}
      {location.pathname === ROUTES.THANK_YOU && (
        <img
          src={ThankYouBottomframe}
          alt="logo-top"
          className="ty-logo-frame-bottom"
        />
      )}
      {location.pathname !== ROUTES.THANK_YOU && (
        <img src={Footer} alt="footer" className="footer-img" />
      )}
    </div>
  );
};

export default MainLayout;
