// CashbackPayout.tsx
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import CameraIcon from "../../assets/images/camera-icon.png";
import MainLayout from "../../layouts/MainLayout";
import "./CashbackPayout.scss";
import { toBase64 } from "../../lib/utils";
import API from "../../api";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../lib/consts";
import { Trans, useTranslation } from "react-i18next";
import { error } from "console";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
import buttonimg from "../../assets/images/home/Button-img.png";
import dropdownlogo from "../../assets/images/bg/faqdropdown.png";
import otplogo from "../../assets/images/home/Otp.png";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

// Import statements...

interface CashbackPayoutProps {
  onSubmit: (values: any) => void;
}

const Code: React.FC<CashbackPayoutProps> = ({ onSubmit }) => {
  const [showFaqDropdown, setshowFaqDropdown] = useState(false);
  const { t }: { t: Function } = useTranslation();
  const CodeValidation = Yup.object().shape({
    code: Yup.string()
      .required("Enter valid Code")
      .matches(/^.{6,7}$/, "Enter valid Code"),

    // Add validation schema for specific payout methods if needed
  });
  const userName = useSelector((state: RootState) => state.userReducer.name);
  const navigate = useNavigate();

  return (
    <MainLayout className="register-page">
      <Formik
        initialValues={{
          code: "",
        }}
        validationSchema={CodeValidation}
        onSubmit={async (values, { setSubmitting }) => {
          // Add logic for handling the selected payout method
          // console.log(values);
          // setSubmitting(false);
          // navigate(ROUTES.SELECT_PAYOUT);

          const { amount } = await API.verifyCODE(values);
          localStorage.setItem("amount", amount.toString());
          gtagTrackEvent(GA_EVENTS.CODE_click);
          onSubmit(values);
          setSubmitting(false);
          navigate(ROUTES.SELECT_PAYOUT, { replace: true });

          //   console.log(errors);
          // Navigate to the Thank You page
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
        }) => (
          <Form className="cashback-payout-form" onSubmit={handleSubmit}>
            {/* Add your payout method selection UI here */}
            <div className="cashback-payout-title">
              {/* {t("cashbackPayout.choosePayout")} */}
            </div>

            {/* Add input fields for selected payout method */}
            {/* Example: UPI input fields */}
            <div className="code-input-container">
              <p className="code-name-text">Hi {userName}</p>
              <div className="code-container">
                <img src={otplogo} alt="otp-logo" className="code-logo" />
                <p className="form-title ">
                  Enter your <br />
                  Unique Code:
                </p>
              </div>
              <p className="code-sub-text">
                Find this{" "}
                <span className="code-blu-text">6-digit scratch code </span>on
                your note
              </p>

              <div className="input-group">
                {/* <label htmlFor="code"></label> */}
                <Field
                  type="text"
                  id="code"
                  name="code"
                  maxLength={7}
                  onChange={(e: any) => {
                    handleChange(e);
                    gtagTrackEvent(GA_EVENTS.Entered_Text_box);
                  }}
                  // placeholder={t("cashbackPayout.enterUpi")}
                />
                <ErrorMessage name="code" component="p" className="error" />
              </div>
            </div>

            <button className="btn btn-primary1" type="submit">
              <img src={buttonimg} alt="btn" />
              <p className="button-text">Submit</p>
            </button>
            <p className="proceed-text">
              Proceed to enter the unique scratch code <br />
              on your note
            </p>
          </Form>
        )}
      </Formik>
      <div className="main-faq">
        <div className="main-faq-wrapper">
          <div
            className="main-faq-container"
            onClick={() => {
              setshowFaqDropdown(!showFaqDropdown);
              gtagTrackEvent(GA_EVENTS.Click_FAQs);
            }}
          >
            <div className="faq-title1">
              <img src={otplogo} alt="faq-logo" className="faq-logo" />
              <p className="faq-title">FAQs:</p>
            </div>
            <div className="faq-container">
              <img
                // onClick={() => setshowFaqDropdown(!showFaqDropdown)}
                src={dropdownlogo}
                alt="dropdown-logo"
                className="dropdown-logo"
              />
            </div>
          </div>
          <div className="faq-dropdown">
            {showFaqDropdown && (
              <div className="faq-content">
                <p dir="ltr">
                  <strong>
                    Question 1: Till what date can I redeem the cashback from my
                    Bank of Small Wins note?
                  </strong>
                </p>
                <p dir="ltr">
                  The cashback redemption period is valid from March 15th, 2024,
                  to June 12th, 2024. Any redemption attempts beyond this period
                  will be invalid.
                </p>
                <p dir="ltr">
                  <strong>
                    Question 2: What is the promotional website to register and
                    where can I read the terms and conditions?
                  </strong>
                </p>
                <p dir="ltr">
                  For detailed Terms and Conditions visit{" "}
                  <a href="http://www.bosw.in/"></a>
                  <a href="http://www.bosw.in">www.bosw.in</a>
                </p>
                <p dir="ltr">
                  <strong>
                    Questions 3: Question: How can I get cashback through the
                    GoodDay currency I received from Myntra/U-Clean/Bombay
                    Closet Cleanse jeans?
                  </strong>
                </p>
                <p dir="ltr">
                  1. Enter your name and mobile number and submit to receive OTP
                  on your registered mobile number.
                </p>
                <p dir="ltr">
                  2. Submit the OTP to authenticate your mobile number.
                </p>
                <p dir="ltr">
                  3. Enter the unique code printed on your Bank of Small Wins
                  note.
                </p>
                <p dir="ltr">
                  4. Choose the preferred mode of cashback, enter the details
                  and submit. You will receive your cashback in your selected
                  mode within 24-48 business hours.
                </p>
                <p dir="ltr">
                  5. For the note with the cashback of Rs. 50,000, you will
                  receive a call from BigCity (Our Campaign Partner) for
                  verification.
                </p>
                <p dir="ltr">
                  <strong>
                    Question 4: What is Britannia Good Day’s Bank of Small Wins
                    about?
                  </strong>
                </p>
                <p dir="ltr">
                  As the joy of finding cash in our pockets diminishes with the
                  rise of digital payments, Bank of Small Wins seeks to reignite
                  that excitement. We're here to bring a smile to your face by
                  pre-loading cash into the pants you purchase from Myntra, the
                  ones you send for laundry to U-clean, and even the ones you
                  thrift from Bombay Closet Cleanse!
                </p>
                <p dir="ltr">
                  So, imagine the delight when you discover a Britannia Good Day
                  Bank of Small Wins note tucked into your new pants. This
                  little surprise could earn you a cashback of up to Rs. 50,000
                  (T&amp;C apply).
                </p>
                <p dir="ltr">
                  But wait, there's more! Alongside the chance to win cashback,
                  you could also win three months' worth of Good Day biscuits,
                  an autograph from a celebrity or a surprise bonus small win.
                </p>
                <p dir="ltr">
                  <strong>
                    Question 5: How many Bank of Small Wins notes can I redeem
                    the cashback from?
                  </strong>
                </p>
                <p dir="ltr">
                  You're welcome to purchase as many pants and receive as many
                  Bank of Small Wins notes as you like. However, please note
                  that you can only redeem the cashback twice using the same
                  mobile number and email ID.
                </p>
                <p dir="ltr">
                  <strong>
                    Question 6: Can I use the same scratch code to redeem the
                    cashback from my Bank of Small Wins note?
                  </strong>
                </p>
                <p dir="ltr">
                  One code can be used only once for participation. The
                  responsibility of providing a valid, working UPI ID lies with
                  the participant. Even by mistake, if a participant enters an
                  erroneous UPI ID, the unique code will be treated as used and
                  cannot be used again.
                </p>
                <p dir="ltr">
                  <strong>
                    Question 7: Is the promotion valid throughout India?{" "}
                  </strong>
                  <br />
                  The promotion is valid across India, excluding the state of
                  Tamil Nadu.
                </p>
                <p dir="ltr">
                  <strong>
                    Question 8: I have registered the unique code, but have yet
                    to receive my OTP, what will be the next steps?
                  </strong>
                </p>
                <p dir="ltr">
                  If you are registered with the Do Not Disturb (DND) service,
                  you will not receive an SMS confirmation from us. Please mail
                  us at feedback@bigcity.in for any queries.
                </p>
                <p dir="ltr">
                  <strong>
                    {" "}
                    Question 9: Will I receive the full Rs.50,000 cashback if I
                    win?
                  </strong>
                </p>
                <p dir="ltr">
                  If you win the Rs. 50,000 cashback, you'll receive the
                  remaining amount after deducting Rs. 15,600, which is
                  equivalent to 31.2% of the cashback value, as gift tax.
                </p>
                <p dir="ltr">
                  <strong>
                    {" "}
                    Question 10. For other queries who do I contact?
                  </strong>
                </p>
                <p dir="ltr">
                  For any queries write to us at{" "}
                  <a href="feedback@bigciyty.in"></a>
                  <a href="http://feedback@bigcity.in">feedback@bigciyty.in</a>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Code;
