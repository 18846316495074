import MainLayout from "../../layouts/MainLayout";
import { Form, Formik, replace } from "formik";
import * as Yup from "yup";
import "./Register.scss";
import { useEffect, useRef, useState } from "react";
import API from "../../api";
import { ERROR_IDS } from "../../api/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { LANGUAGE, LANGUAGES, ROUTES } from "../../lib/consts";
import store from "../../store/store";
import { setAccessToken } from "../../store/actions/authAction";
import { useTranslation } from "react-i18next";
import { MODAL_TYPES, useGlobalModalContext } from "../../helpers/GlobalModal";
import CashbackPayout from "../SelectPayout/CashbackPayout";
import { useAuthentication } from "../../hooks/useAuthentication";
import { addCaptchaScript, hideCaptchaBadge } from "../../lib/utils";
import { setUserIdentification } from "../../store/actions/authAction";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
import buttonimg from "../../assets/images/home/Button-img.png";
import mobilelogo from "../../assets/images/home/mobile number.png";
import namelogo from "../../assets/images/home/Name.png";
import otplogo from "../../assets/images/home/Otp.png";
import bottomframe from "../../assets/images/bg/bottomframe.png";
import dropdownlogo from "../../assets/images/bg/faqdropdown.png";
import { setUserName } from "../../store/actions/userAction";

declare var grecaptcha: any;

const Register = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const { showModal } = useGlobalModalContext();
  const [resendTimer, setResendTimer] = useState(60);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showPayoutForm, setShowPayoutForm] = useState(false);
  const [showFaqDropdown, setshowFaqDropdown] = useState(false);

  const { isLoggedIn } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();

  const timer = useRef<any | null>(null);

  const { t } = useTranslation();
  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
    setCheckboxError("");
  };

  useEffect(() => {
    if (isLoggedIn) {
      // If the user is logged in, redirect to payoutform
      // navigate(ROUTES.SELECT_PAYOUT);
    }
  }, [isLoggedIn]);
  const RegisterValidation = Yup.object().shape({
    name: Yup.string()
      .required(t("registerPage.errors.invalidName") || "Enter valid name")
      .matches(
        /^[a-zA-Z]{1}[a-zA-Z\s]{2,}$/,
        t("registerPage.errors.invalidName") || "Enter valid name"
      ),
    mobile: Yup.string()
      .required(
        t("registerPage.errors.invalidMobile") ||
          "*Please enter a valid 10-digit number"
      )
      .matches(
        /^[56789][0-9]{9}$/,
        t("registerPage.errors.invalidMobile") ||
          "*Please enter a valid 10-digit number"
      ),
  });

  const OtpValidation = Yup.object().shape({
    otp: Yup.string()
      .required(
        t("registerPage.errors.invalidOtp") || "*Please enter valid OTP"
      )
      .matches(
        /^[0-9]{6}$/,
        t("registerPage.errors.incorrectOtp") ||
          "*You have entered an incorrect OTP. Please try again"
      ),
  });

  const startTimer = () => {
    if (timer) clearInterval(timer.current);
    let time = 60;
    setResendTimer(time);
    timer.current = setInterval(() => {
      time--;
      setResendTimer(time);
      if (time <= 0) {
        clearInterval(timer.current);
      }
    }, 1000);
  };

  const resendOtp = () => {
    if (resendTimer <= 0) {
      alert("otp");
      startTimer();
      API.resendOTP()
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(
    () => () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    },
    []
  );

  function renderRegisterForm() {
    return (
      <Formik
        key="register-form"
        initialValues={{
          name: "",
          mobile: "",
        }}
        validationSchema={RegisterValidation}
        onSubmit={(values, errors) => {
          // setShowOtpForm(true);
          store.dispatch(setUserName(values.name));

          // if (isAgreed) {
          // setShowOtpForm(true);
          API.register(values).then(() => {
            gtagTrackEvent(GA_EVENTS.Registered);
            setShowOtpForm(true);
            startTimer();
          });
          // } else {
          //   setCheckboxError("Please accept the terms and conditions");
          //   setShowError(true); // Optionally, show an error message
          // }
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <Form
            // onSubmit={handleSubmit}
            // onClick={() => setShowStateDropdown(false)}
            >
              <div className="register-form">
                <div className="name-container">
                  <img src={namelogo} alt="name-logo" className="name-logo" />
                  <p className="form-title ">Name:</p>
                </div>
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      gtagTrackEvent(GA_EVENTS.Entered_Name);
                    }}
                    value={values.name}
                    name="name"
                    onBlur={handleBlur}
                  />
                </div>
                <div className="mobile-container">
                  <img
                    src={mobilelogo}
                    alt="mobile-logo"
                    className="mobile-logo"
                  />
                  <p className="form-title ">
                    Enter your <br />
                    mobile number:
                  </p>
                </div>
                <p className="cashback-text">
                  The number will be linked to your UPI ID to receive the
                  <span className="cashback-text-span"> cashback</span>
                </p>

                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={(e) => {
                      handleChange(e);
                      gtagTrackEvent(GA_EVENTS.Entered_Phone_Number);
                    }}
                    value={values.mobile}
                    name="mobile"
                    maxLength={10}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              {errors.name && touched.mobile && (
                <p className="error">{errors.name}</p>
              )}
              {!errors.name && touched.mobile && (
                <p className="error">{errors.mobile}</p>
              )}
              <button className="btn btn-primary1" type="submit">
                <img src={buttonimg} alt="btn" />
                <p className="button-text">Get OTP</p>
              </button>
              <p className="proceed-text">
                Proceed to enter the unique scratch code <br />
                on your note
              </p>
            </Form>
          );
        }}
      </Formik>
    );
  }

  function renderOtpForm() {
    return (
      <Formik
        key="otp-form"
        initialValues={{
          otp: "",
        }}
        validationSchema={OtpValidation}
        onSubmit={(values, errors) => {
          grecaptcha
            .execute(process.env.REACT_APP_CAPTCHA_SITE_KEY, {
              action: "verify_otp",
            })
            .then((token: string) => {
              // console.log("Token:", token);
              API.verifyOTP(values.otp, token)
                .then((response) => {
                  hideCaptchaBadge();
                  store.dispatch(setAccessToken(response.accessToken));
                  navigate(ROUTES.CODE, { replace: true });
                  gtagTrackEvent(GA_EVENTS.Verify_OTP);
                })
                .catch((err) => {
                  const { messageId } = err;
                  switch (messageId) {
                    case ERROR_IDS.INVALID_OTP:
                      errors.setErrors({
                        otp:
                          t("registerPage.errors.invalidOtp") ||
                          "Please enter a valid OTP",
                      });
                      break;
                    default:
                      errors.setErrors({
                        otp: err.message,
                      });
                      break;
                  }
                });
            });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="otp-form">
                <div className="otp-container">
                  <img src={otplogo} alt="otp-logo" className="otp-logo" />
                  <p className="form-title">OTP:</p>
                </div>
                <p className="cashback-text">
                  The number will be linked to your UPI ID to receive the
                  <span className="cashback-text-span"> cashback</span>
                </p>
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.otp}
                    name="otp"
                    maxLength={6}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.otp && touched.otp && (
                  <p className="error">{errors.otp}</p>
                )}
                <div className="flex-center timer-wrapper">
                  {resendTimer > 0 && (
                    <div className="timer flex-center">00.{resendTimer}</div>
                    // <div className="timer flex-center">00:60</div>
                  )}
                  <span></span>
                </div>
                <span
                  className="link"
                  onClick={() => {
                    resendOtp();
                    gtagTrackEvent(GA_EVENTS.Click_Resend_OTP);
                  }}
                >
                  Resend OTP
                </span>
              </div>

              <button className="btn btn-primary1" type="submit">
                <img src={buttonimg} alt="btn" />
                <p className="button-text">Verify</p>
              </button>
              <p className="proceed-text">
                Proceed to enter the unique scratch code <br />
                on your note
              </p>
            </Form>
          );
        }}
      </Formik>
    );
  }

  function renderPayoutForm() {
    return (
      <CashbackPayout
        onSubmit={(values) => {
          setShowPayoutForm(false); // Hide the payout form after submission
        }}
      />
    );
  }

  return (
    <MainLayout className="register-page">
      {showOtpForm ? renderOtpForm() : renderRegisterForm()}
      <div className="main-faq">
        <div className="main-faq-wrapper">
          <div
            className="main-faq-container"
            onClick={() => {
              setshowFaqDropdown(!showFaqDropdown);
              gtagTrackEvent(GA_EVENTS.Click_FAQs);
            }}
          >
            <div className="faq-title1">
              <img src={otplogo} alt="faq-logo" className="faq-logo" />
              <p className="faq-title">FAQs:</p>
            </div>
            <div className="faq-container">
              <img
                // onClick={() => {
                //   setshowFaqDropdown(!showFaqDropdown);
                //   gtagTrackEvent(GA_EVENTS.Click_FAQs);
                // }}
                src={dropdownlogo}
                alt="dropdown-logo"
                className="dropdown-logo"
              />
            </div>
          </div>
          <div className="faq-dropdown">
            {showFaqDropdown && (
              <div className="faq-content">
                <p dir="ltr">
                  <strong>
                    Question 1: Till what date can I redeem the cashback from my
                    Bank of Small Wins note?
                  </strong>
                </p>
                <p dir="ltr">
                  The cashback redemption period is valid from March 15th, 2024,
                  to June 12th, 2024. Any redemption attempts beyond this period
                  will be invalid.
                </p>
                <p dir="ltr">
                  <strong>
                    Question 2: What is the promotional website to register and
                    where can I read the terms and conditions?
                  </strong>
                </p>
                <p dir="ltr">
                  For detailed Terms and Conditions visit{" "}
                  <a href="http://www.bosw.in/"></a>
                  <a href="http://www.bosw.in">www.bosw.in</a>
                </p>
                <p dir="ltr">
                  <strong>
                    Questions 3: Question: How can I get cashback through the
                    GoodDay currency I received from Myntra/U-Clean/Bombay
                    Closet Cleanse jeans?
                  </strong>
                </p>
                <p dir="ltr">
                  1. Enter your name and mobile number and submit to receive OTP
                  on your registered mobile number.
                </p>
                <p dir="ltr">
                  2. Submit the OTP to authenticate your mobile number.
                </p>
                <p dir="ltr">
                  3. Enter the unique code printed on your Bank of Small Wins
                  note.
                </p>
                <p dir="ltr">
                  4. Choose the preferred mode of cashback, enter the details
                  and submit. You will receive your cashback in your selected
                  mode within 24-48 business hours.
                </p>
                <p dir="ltr">
                  5. For the note with the cashback of Rs. 50,000, you will
                  receive a call from BigCity (Our Campaign Partner) for
                  verification.
                </p>
                <p dir="ltr">
                  <strong>
                    Question 4: What is Britannia Good Day’s Bank of Small Wins
                    about?
                  </strong>
                </p>
                <p dir="ltr">
                  As the joy of finding cash in our pockets diminishes with the
                  rise of digital payments, Bank of Small Wins seeks to reignite
                  that excitement. We're here to bring a smile to your face by
                  pre-loading cash into the pants you purchase from Myntra, the
                  ones you send for laundry to U-clean, and even the ones you
                  thrift from Bombay Closet Cleanse!
                </p>
                <p dir="ltr">
                  So, imagine the delight when you discover a Britannia Good Day
                  Bank of Small Wins note tucked into your new pants. This
                  little surprise could earn you a cashback of up to Rs. 50,000
                  (T&amp;C apply).
                </p>
                <p dir="ltr">
                  But wait, there's more! Alongside the chance to win cashback,
                  you could also win three months' worth of Good Day biscuits,
                  an autograph from a celebrity or a surprise bonus small win.
                </p>
                <p dir="ltr">
                  <strong>
                    Question 5: How many Bank of Small Wins notes can I redeem
                    the cashback from?
                  </strong>
                </p>
                <p dir="ltr">
                  You're welcome to purchase as many pants and receive as many
                  Bank of Small Wins notes as you like. However, please note
                  that you can only redeem the cashback twice using the same
                  mobile number and email ID.
                </p>
                <p dir="ltr">
                  <strong>
                    Question 6: Can I use the same scratch code to redeem the
                    cashback from my Bank of Small Wins note?
                  </strong>
                </p>
                <p dir="ltr">
                  One code can be used only once for participation. The
                  responsibility of providing a valid, working UPI ID lies with
                  the participant. Even by mistake, if a participant enters an
                  erroneous UPI ID, the unique code will be treated as used and
                  cannot be used again.
                </p>
                <p dir="ltr">
                  <strong>
                    Question 7: Is the promotion valid throughout India?{" "}
                  </strong>
                  <br />
                  The promotion is valid across India, excluding the state of
                  Tamil Nadu.
                </p>
                <p dir="ltr">
                  <strong>
                    Question 8: I have registered the unique code, but have yet
                    to receive my OTP, what will be the next steps?
                  </strong>
                </p>
                <p dir="ltr">
                  If you are registered with the Do Not Disturb (DND) service,
                  you will not receive an SMS confirmation from us. Please mail
                  us at feedback@bigcity.in for any queries.
                </p>
                <p dir="ltr">
                  <strong>
                    {" "}
                    Question 9: Will I receive the full Rs.50,000 cashback if I
                    win?
                  </strong>
                </p>
                <p dir="ltr">
                  If you win the Rs. 50,000 cashback, you'll receive the
                  remaining amount after deducting Rs. 15,600, which is
                  equivalent to 31.2% of the cashback value, as gift tax.
                </p>
                <p dir="ltr">
                  <strong>
                    {" "}
                    Question 10: For other queries who do I contact?
                  </strong>
                </p>
                <p dir="ltr">
                  For any queries write to us at{" "}
                  <a href="feedback@bigciyty.in"></a>
                  <a href="http://feedback@bigcity.in">feedback@bigcity.in</a>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Register;
