import {
  BaseResponse,
  CreateUserPayload,
  CreateUserResponse,
  RegisterPayload,
  UploadSelfieResponse,
  VerifyCodeResponse,
  VerifyOtpResponse,
  verifyCodePayload,

  // StatePayload,
} from "../interface/api";
import {
  decryptData,
  sendEncrytedData,
  authorisedEncrytedApiCall,
} from "./encrypt";
import { defaultCatch, fetchHandlerText, responseHelper } from "./utils";
import store from "../store/store";
import { getCookie } from "../lib/utils";

const jsonHeaders: { [key: string]: string } = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

class APIS {
  private showLoader = (loaderTitle?: string | undefined) => {};
  private hideLoader = (loaderTitle?: string | undefined) => {};
  private static instance: APIS | null = null;
  public instanceId = "TEST";
  private static activityTimer: NodeJS.Timer;

  constructor(instanceId: string) {
    this.instanceId = instanceId;
    // document.addEventListener("click", this.logActivity);
  }

  static getInstance() {
    return APIS.instance || (APIS.instance = new APIS("TEST NEW 1"));
  }

  initialize(
    showLoader: (loaderTitle?: string | undefined) => void,
    hideLoader: () => void
  ) {
    this.showLoader = showLoader;
    this.hideLoader = hideLoader;
  }

  // private logActivity() {
  //   // console.log("LOG", "ACTIVTY");
  //   clearTimeout(APIS.activityTimer);
  //   const auth = store.getState().authReducer;
  //   if (auth && auth.accessToken) {
  //     // console.log("LOG", "INACTIVITY TIMER STARTED", new Date());
  //     APIS.activityTimer = setTimeout(() => {
  //       // console.log("LOG", "LOGGING USER OUT", new Date());
  //       // logoutUser();
  //       toast.info("Your session has been expired");
  //     }, 20 * 60 * 1000);
  //   }
  // }

  async createUser(): Promise<CreateUserResponse> {
    const payload: CreateUserPayload = {};
    const state = store.getState();
    const { accessToken } = state.authReducer;
    let masterKey = getCookie("good-day-small-wins-id");
    if (masterKey) {
      payload.masterKey = masterKey;
    }
    const headers = jsonHeaders;
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("utm_source")) {
      payload.utm_source = urlParams.get("utm_source");
    }
    if (urlParams.get("utm_medium")) {
      payload.utm_medium = urlParams.get("utm_medium");
    }
    if (urlParams.get("utm_campaign")) {
      payload.utm_campaign = urlParams.get("utm_campaign");
    }
    if (urlParams.get("utm_content")) {
      payload.utm_content = urlParams.get("utm_content");
    }
    if (urlParams.get("utm_term")) {
      payload.utm_term = urlParams.get("utm_term");
    }

    this.showLoader("Starting session...");
    //return fetch(`${process.env.REACT_APP_API_BASE_URL}collect`, {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}users`, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    })
      .then(fetchHandlerText)
      .then(decryptData)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }

  // private ipLookup(): Promise<IpWhoIsData> {
  //   this.showLoader("Setting up...");
  //   return fetch("https://ipwhois.pro/?key=yfybFTXnrwjxJuJr")
  //     .then(fetchHandler)
  //     .then((response) => response.data)
  //     .finally(this.hideLoader);
  // }

  getStarted(type: string): Promise<BaseResponse> {
    // this.logActivity();
    this.showLoader("Starting...");
    return sendEncrytedData("users/action/", { type })
      .then(fetchHandlerText)
      .then(decryptData)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }
  // stateList(): Promise<StatePayload> {
  //   // this.logActivity();
  //   const endpoint = "users/stateList";
  //   const state = store.getState();
  //   const { accessToken } = state.authReducer;
  //   const headers = {
  //     ...jsonHeaders,
  //     ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
  //   };
  //   this.showLoader("Fetching data...");
  //   return fetch(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, {
  //     method: "POST",
  //     headers,
  //   })
  //     .then(fetchHandlerText)
  //     .then(decryptData)
  //     .then(responseHelper)
  //     .catch(defaultCatch)
  //     .finally(this.hideLoader);
  // }

  register(payload: RegisterPayload): Promise<BaseResponse> {
    // this.logActivity();
    this.showLoader("Saving details...");
    return sendEncrytedData("users/register/", payload)
      .then(fetchHandlerText)
      .then(decryptData)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }
  resendOTP(): Promise<BaseResponse> {
    // this.logActivity();
    this.showLoader("Resending OTP...");
    return sendEncrytedData("users/resendOTP/", {})
      .then(fetchHandlerText)
      .then(decryptData)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }

  verifyOTP(otp: string, token: string): Promise<VerifyOtpResponse> {
    // this.logActivity();
    this.showLoader("Verifying OTP...");
    return sendEncrytedData("users/verifyOTP/", { otp, token })
      .then(fetchHandlerText)
      .then(decryptData)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }

  verifyCODE(payload: verifyCodePayload): Promise<VerifyCodeResponse> {
    // this.logActivity();
    this.showLoader("Verifying Code...");
    return authorisedEncrytedApiCall("users/addUniqueCode/", payload)
      .then(fetchHandlerText)
      .then(decryptData)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }

  saveUPIDetails(upiId: string): Promise<VerifyOtpResponse> {
    // this.logActivity();
    this.showLoader("Saving Details...");
    return authorisedEncrytedApiCall("users/addUpi/", { upiId })
      .then(fetchHandlerText)
      .then(decryptData)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }

  saveNEFTDetails(
    name: string,
    account_number: string,
    ifsc: string
  ): Promise<VerifyOtpResponse> {
    // this.logActivity();
    this.showLoader("Saving Details...");
    return authorisedEncrytedApiCall("users/addNeft/", {
      name,
      account_number,
      ifsc,
    })
      .then(fetchHandlerText)
      .then(decryptData)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }

  sendSMS(): Promise<BaseResponse> {
    // Assuming you have a method to show a loader
    this.showLoader("Sending SMS...");
    return authorisedEncrytedApiCall("users/sentSMS/")
      .then(fetchHandlerText)
      .then(decryptData)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(() => this.hideLoader());
  }

  saveEWalletDetails(mobile: string): Promise<VerifyOtpResponse> {
    // this.logActivity();
    this.showLoader("Saving Details...");
    return authorisedEncrytedApiCall("users/addWallet/", { mobile })
      .then(fetchHandlerText)
      .then(decryptData)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }

  uploadSelfie(selfie: string): Promise<UploadSelfieResponse> {
    // this.logActivity();
    this.showLoader("Uploading selfie...");
    return authorisedEncrytedApiCall("users/uploadSelfie/", { selfie })
      .then(fetchHandlerText)
      .then(decryptData)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }
}
const API = APIS.getInstance();

export default API;
