// CashbackPayout.tsx
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import CameraIcon from "../../assets/images/camera-icon.png";
import MainLayout from "../../layouts/MainLayout";
import "./CashbackPayout.scss";
import { toBase64 } from "../../lib/utils";
import API from "../../api";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../lib/consts";
import { Trans, useTranslation } from "react-i18next";
import { error } from "console";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
import buttonimg from "../../assets/images/home/Button-img.png";
import upi from "../../assets/images/bg/upi icon.png";
import neft from "../../assets/images/bg/Neft icon.png";
import dropdownlogo from "../../assets/images/bg/faqdropdown.png";
import otplogo from "../../assets/images/home/Otp.png";
import upiarrow from "../../assets/images/bg/upiarrow.png";

interface CashbackPayoutProps {
  onSubmit: (values: any) => void;
}

const CashbackPayout: React.FC<CashbackPayoutProps> = ({ onSubmit }) => {
  const { t }: { t: Function } = useTranslation();
  const [showFaqDropdown, setshowFaqDropdown] = useState(false);

  const PayoutValidation = Yup.object().shape({
    payoutMethod: Yup.string().required(
      t("cashbackPayout.errors.chooseMethod")
    ),
    // Add validation schema for specific payout methods if needed
  });

  const navigate = useNavigate();
  const amount = localStorage.getItem("amount");

  return (
    <>
      <p className="reward-text-container">
        You are now eligible <br />
        for direct cashback of
        <p className="amount"> ₹{amount}/-</p>
      </p>
      <MainLayout className="register-page">
        <Formik
          initialValues={{
            payoutMethod: "upi",
            // Add more fields based on the selected payout method
            upiNumber: "",
            name: "", // Add Name for NEFT
            accountNumber: "", // Add Account Number for NEFT
            ifscCode: "", // Add IFSC Code for NEFT
            mobileNumber: "", // Add Mobile Number for E-Wallet
          }}
          validationSchema={PayoutValidation}
          onSubmit={(values, { setSubmitting }) => {
            gtagTrackEvent(GA_EVENTS.Click_Submit);
            // console.log(values);

            switch (values.payoutMethod) {
              case "upi":
                navigate(ROUTES.UPI_PAYOUT, { replace: true });
                break;
              case "neft":
                navigate(ROUTES.BANK_PAYOUT, { replace: true });
                break;
              default:
                break;
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            errors,
          }) => (
            <Form className="cashback-payout-form" onSubmit={handleSubmit}>
              {/* Add your payout method selection UI here */}
              <div className="cashback-payout-title">
                {/* {t("cashbackPayout.choosePayout")} */}
              </div>

              <div className="payout-option-container">
                <p className="upi-title">Pick a Pocket?</p>
                <p className="upi-sub-title">UPI , NEFT</p>
                <div
                  className={`payout-option ${
                    values.payoutMethod === "upi" ? "selected" : ""
                  }`}
                  onClick={(e) => {
                    // e.target.classList.add("selected");
                    setFieldValue("payoutMethod", "upi");
                    gtagTrackEvent(GA_EVENTS.Click_UPI);
                  }}
                >
                  <div className="upi-container">
                    <img src={upi} alt="Logo" className="upilogo" />
                    Receive on UPI ID
                    {/* <img src={upiarrow} alt="Logo" className="upiarrow" /> */}
                  </div>
                </div>
                <div
                  className={`payout-option ${
                    values.payoutMethod === "neft" ? "selected" : ""
                  }`}
                  onClick={(e) => {
                    // e.target.classList.add("selected");
                    setFieldValue("payoutMethod", "neft");
                    gtagTrackEvent(GA_EVENTS.Click_NEFT);
                  }}
                >
                  <div className="upi-container">
                    <img src={neft} alt="Logo" className="upilogo" />
                    Receive through NEFT
                    {/* <img src={upiarrow} alt="Logo" className="upiarrow" /> */}
                  </div>
                </div>
              </div>

              <button className="btn btn-primary1" type="submit">
                <img src={buttonimg} alt="btn" />
                <p className="button-text">Submit</p>
              </button>
              <p className="proceed-text">Proceed to Transaction gateway</p>
            </Form>
          )}
        </Formik>
        <div className="main-faq">
          <div className="main-faq-wrapper">
            <div
              className="main-faq-container"
              onClick={() => {
                setshowFaqDropdown(!showFaqDropdown);
                gtagTrackEvent(GA_EVENTS.Click_FAQs);
              }}
            >
              <div className="faq-title1">
                <img src={otplogo} alt="faq-logo" className="faq-logo" />
                <p className="faq-title">FAQs:</p>
              </div>
              <div className="faq-container">
                <img
                  // onClick={() => setshowFaqDropdown(!showFaqDropdown)}
                  src={dropdownlogo}
                  alt="dropdown-logo"
                  className="dropdown-logo"
                />
              </div>
            </div>
            <div className="faq-dropdown">
              {showFaqDropdown && (
                <div className="faq-content">
                  <p dir="ltr">
                    <strong>
                      Question 1: Till what date can I redeem the cashback from
                      my Bank of Small Wins note?
                    </strong>
                  </p>
                  <p dir="ltr">
                    The cashback redemption period is valid from March 15th,
                    2024, to June 12th, 2024. Any redemption attempts beyond
                    this period will be invalid.
                  </p>
                  <p dir="ltr">
                    <strong>
                      Question 2: What is the promotional website to register
                      and where can I read the terms and conditions?
                    </strong>
                  </p>
                  <p dir="ltr">
                    For detailed Terms and Conditions visit{" "}
                    <a href="http://www.bosw.in/"></a>
                    <a href="http://www.bosw.in">www.bosw.in</a>
                  </p>
                  <p dir="ltr">
                    <strong>
                      Questions 3: Question: How can I get cashback through the
                      GoodDay currency I received from Myntra/U-Clean/Bombay
                      Closet Cleanse jeans?
                    </strong>
                  </p>
                  <p dir="ltr">
                    1. Enter your name and mobile number and submit to receive
                    OTP on your registered mobile number.
                  </p>
                  <p dir="ltr">
                    2. Submit the OTP to authenticate your mobile number.
                  </p>
                  <p dir="ltr">
                    3. Enter the unique code printed on your Bank of Small Wins
                    note.
                  </p>
                  <p dir="ltr">
                    4. Choose the preferred mode of cashback, enter the details
                    and submit. You will receive your cashback in your selected
                    mode within 24-48 business hours.
                  </p>
                  <p dir="ltr">
                    5. For the note with the cashback of Rs. 50,000, you will
                    receive a call from BigCity (Our Campaign Partner) for
                    verification.
                  </p>
                  <p dir="ltr">
                    <strong>
                      Question 4: What is Britannia Good Day’s Bank of Small
                      Wins about?
                    </strong>
                  </p>
                  <p dir="ltr">
                    As the joy of finding cash in our pockets diminishes with
                    the rise of digital payments, Bank of Small Wins seeks to
                    reignite that excitement. We're here to bring a smile to
                    your face by pre-loading cash into the pants you purchase
                    from Myntra, the ones you send for laundry to U-clean, and
                    even the ones you thrift from Bombay Closet Cleanse!
                  </p>
                  <p dir="ltr">
                    So, imagine the delight when you discover a Britannia Good
                    Day Bank of Small Wins note tucked into your new pants. This
                    little surprise could earn you a cashback of up to Rs.
                    50,000 (T&amp;C apply).
                  </p>
                  <p dir="ltr">
                    But wait, there's more! Alongside the chance to win
                    cashback, you could also win three months' worth of Good Day
                    biscuits, an autograph from a celebrity or a surprise bonus
                    small win.
                  </p>
                  <p dir="ltr">
                    <strong>
                      Question 5: How many Bank of Small Wins notes can I redeem
                      the cashback from?
                    </strong>
                  </p>
                  <p dir="ltr">
                    You're welcome to purchase as many pants and receive as many
                    Bank of Small Wins notes as you like. However, please note
                    that you can only redeem the cashback twice using the same
                    mobile number and email ID.
                  </p>
                  <p dir="ltr">
                    <strong>
                      Question 6: Can I use the same scratch code to redeem the
                      cashback from my Bank of Small Wins note?
                    </strong>
                  </p>
                  <p dir="ltr">
                    One code can be used only once for participation. The
                    responsibility of providing a valid, working UPI ID lies
                    with the participant. Even by mistake, if a participant
                    enters an erroneous UPI ID, the unique code will be treated
                    as used and cannot be used again.
                  </p>
                  <p dir="ltr">
                    <strong>
                      Question 7: Is the promotion valid throughout India?{" "}
                    </strong>
                    <br />
                    The promotion is valid across India, excluding the state of
                    Tamil Nadu.
                  </p>
                  <p dir="ltr">
                    <strong>
                      Question 8: I have registered the unique code, but have
                      yet to receive my OTP, what will be the next steps?
                    </strong>
                  </p>
                  <p dir="ltr">
                    If you are registered with the Do Not Disturb (DND) service,
                    you will not receive an SMS confirmation from us. Please
                    mail us at feedback@bigcity.in for any queries.
                  </p>
                  <p dir="ltr">
                    <strong>
                      {" "}
                      Question 9: Will I receive the full Rs.50,000 cashback if
                      I win?
                    </strong>
                  </p>
                  <p dir="ltr">
                    If you win the Rs. 50,000 cashback, you'll receive the
                    remaining amount after deducting Rs. 15,600, which is
                    equivalent to 31.2% of the cashback value, as gift tax.
                  </p>
                  <p dir="ltr">
                    <strong>
                      {" "}
                      Question 10: For other queries who do I contact?
                    </strong>
                  </p>
                  <p dir="ltr">
                    For any queries write to us at{" "}
                    <a href="feedback@bigciyty.in"></a>
                    <a href="http://feedback@bigcity.in">
                      feedback@bigciyty.in
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default CashbackPayout;
