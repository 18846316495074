import ContactUsPopup from "./ContactUsPopup";
import TermsPopup from "./TermsPopup";
import HowTORedeemPopup from "./HowToRedeemPopup";

const popups = {
  ContactUsPopup,
  TermsPopup,
  HowTORedeemPopup,
};

export default popups;
