import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";

const HowTORedeemPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="howtoredeem-us-popup" title={"How to Redeem"}>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Enter your Name, Mobile number and submit to receive OTP on your
            registered mobile number.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Submit the OTP to authenticate your mobile number.</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Enter unique code printed on the small wins note behind the scratch
            panel.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Choose the preferred mode of cashback, enter the details and submit.
            You will receive your cashback in your selected mode within 24-48
            business hours.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            For cashback of Rs. 50000, You will receive a call from BigCity for
            verification.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            T&amp;C apply <a href="http://www.bosw.in/">www.bosw.in</a>
          </p>
        </li>
      </ol>
    </Popup>
  );
};

export default HowTORedeemPopup;
